/* Marquee styles */
.marquee {
    --gap: 1rem;
    position: relative;
    display: flex;
    overflow: hidden;
    user-select: none;
    gap: var(--gap);
  }
  
  .marquee__content {
    flex-shrink: 0;
    display: flex;
    justify-content: space-around;
    gap: var(--gap);
    min-width: 100%;
  }
  
  @keyframes scroll {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(calc(-100% - var(--gap)));
    }
  }
  
  /* Pause animation when reduced-motion is set */
  @media (prefers-reduced-motion: reduce) {
    .marquee__content {
      animation-play-state: paused !important;
    }
  }
  
  /* Enable animation */
  .enable-animation .marquee__content {
    animation: scroll 10s linear infinite;
  }
  
